<template>
    <div>
        <CRow class="mb-2">
            <CCol md="12">
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Migración comprobantes</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol>
                             <multiselect
                                    class="mb-2 multSelectGesto"
                                    :custom-label="customLabelEmp"
                                    v-model="empSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Empresa" 
                                    label="nombreEmpresa"
                                    track-by="codEmpresa"
                                    :options="empresasInfo"
                                    :multiple="false"
                                    @select="seleccionarEmpresa"></multiselect>
                            </CCol>
                            <CCol>
                                <multiselect
                                    class="mb-2 multSelectGesto"
                                    v-model="anioSeleccionado"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Anio Periodo" 
                                    label="per"
                                    track-by="per"
                                    :options="aniosPeriodo"
                                    :multiple="false"></multiselect>
                            </CCol>
                            <CCol v-show="!periodoAnual">
                                <multiselect
                                    class="mb-2 multSelectGesto"
                                    v-model="mesSeleccionado"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Mes Periodo" 
                                    label="mes"
                                    track-by="mes"
                                    :options="mesesPeriodo"
                                    :multiple="false"></multiselect>
                            </CCol>
                            
                            <CCol>
                                <multiselect
                                    class="mb-2 multSelectGesto"
                                    v-model="tipoMigSeleccionado"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Tipo Mig" 
                                    label="nombre"
                                    track-by="nombre"
                                    :options="tiposMigracion"
                                    :multiple="false"></multiselect>
                            </CCol>
                            <CCol>
                                <span class="align-middle">Periodo Anual</span>
                                <span>
                                    <CSwitch
                                        width="150px"
                                        class="mt-2 mx-1 align-middle mb-2"
                                        shape="pill"
                                        variant="outline"
                                        :checked="periodoAnual"
                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                        @update:checked="cambiarSeleccionPeriodoAnual"
                                    />
                                </span>
                            </CCol>
                        </CRow>

                        <CButton size="sm" class="mb-2 mr-2 btn-class" style="width:auto" @click="subirDocumentosMostrar">
                            Subir Documento
                        </CButton>
                        
                        <CRow v-show="mostrarSubirArchivos">
                            <CCol>
                                <hr>
                                <div class="upload" v-show="!isOption">
                                        <file-upload
                                            class="btn btnPrincipalVapp mr-2"
                                            :post-action="linkSubir"
                                            :headers="headers"
                                            :extensions="extensions"
                                            :accept="accept"
                                            :multiple="multiple"
                                            :directory="directory"
                                            :size="size || 0"
                                            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
                                            :drop="drop"
                                            :drop-directory="dropDirectory"
                                            :add-index="addIndex"
                                            v-model="archivosSubir"
                                            @input-file="inputFile"
                                            ref="upload">
                                            <CIcon :content="$options.freeSet.cilPlus" />
                                            Agregar Archivos
                                        </file-upload>

                                        <button
                                            style="font-size: 11px;"
                                            v-show="archivosSubir.length>0 && !isLoadingSubir"
                                            type="button"
                                            class="btn btn-danger mr-2"
                                            @click.prevent="archivosSubir = []">
                                            <CIcon :content="$options.freeSet.cilBan"/>
                                            Quitar Documentos
                                        </button>

                                        <button
                                            style="font-size: 11px;"
                                            type="button"
                                            class="btn btnPrincipalVapp"
                                            v-if="!$refs.upload || !$refs.upload.active"
                                            @click.prevent="subirTodoActivar(empresa);">
                                            <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                            Subir todo
                                        </button>

                                        <button
                                            style="font-size: 11px;"
                                            type="button"
                                            class="btn btn-danger"
                                            v-else
                                            @click.prevent="$refs.upload.active = false">
                                            <CIcon :content="$options.freeSet.cilXCircle"/>
                                            Detener todo
                                        </button>

                                    <div class="table-responsive">
                                        <a-table
                                            row-key="name"
                                            :columns="columnasSubidaArchivos" 
                                            :data-source="archivosSubir"
                                            :scroll="{ y: 400 }"
                                            class="tableInterior mt-3" 
                                            size="small">
                                            <span slot="nombreArchivo" slot-scope="archivo">
                                                <CRow>
                                                    <CCol>{{archivo.name}}</CCol>
                                                </CRow>
                                            </span>
                                            <template slot="progreso" slot-scope="archivo">
                                                <CRow>
                                                    <CCol>
                                                        <CProgress 
                                                        :value="parseFloat(archivo.progress)"
                                                        :precision="2" 
                                                        show-percentage 
                                                        :animated="true"
                                                        :color="(archivo.error)?'danger':'success'"
                                                        />
                                                    </CCol>
                                                </CRow>
                                            </template>
                                            <template slot="tamanio" slot-scope="archivo">
                                                {{archivo.size | prettyBytes}}
                                            </template>

                                            <template slot="velocidad" slot-scope="archivo">
                                                {{ archivo.speed | prettyBytes }}/s
                                            </template>

                                            <template slot="estado" slot-scope="archivo">
                                                <span v-if="archivo.error" style="color:#7E1146">
                                                    <span v-if="archivo.error === 'size'">
                                                        Archivo demasiado pequeño
                                                    </span>
                                                    <span v-else>{{ archivo.response.mensaje }}</span>
                                                </span>
                                                <!--<CBadge v-if="archivo.error" color="danger">
                                                    <span v-if="archivo.error === 'size'">
                                                        Archivo demasiado pequeño
                                                    </span>
                                                    <span v-else>{{ archivo.response }}</span>
                                                </CBadge>-->
                                                <span v-else-if="archivo.success" style="color: #2eb85c">
                                                    {{archivo.response.mensaje}}
                                                </span>
                                                <CBadge v-else-if="archivo.active" color="warning" >
                                                    Subiendo
                                                </CBadge>
                                                <CBadge v-else color="secondary" >
                                                    En espera
                                                </CBadge>
                                            </template>

                                            <template slot="acciones" slot-scope="archivo">
                                                <CButtonGroup class="mx-1">
                                                    <CButton
                                                        v-if="archivo.active" 
                                                        title="Cancelar subida archivo"
                                                        color="danger" 
                                                        size="sm"
                                                        @click.prevent="archivo.active? $refs.upload.update(archivo, { error: 'Cancelado' }): false">
                                                        <CIcon name="cil-x-circle"/>
                                                    </CButton>

                                                    <CButton
                                                        v-else-if="archivo.error && archivo.error !== 'compressing' && $refs.upload.features.html5"
                                                        title="Recargar archivo"
                                                        color="info" 
                                                        size="sm"
                                                        @click.prevent="$refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})">
                                                        <CIcon :content="$options.freeSet.cilLoopCircular"/>
                                                    </CButton>

                                                    <CButton
                                                        v-else
                                                        title="Subir archivo"
                                                        color="success" 
                                                        size="sm"
                                                        @click.prevent="archivo.success || archivo.error === 'compressing'? false: $refs.upload.update(archivo, { active: true })">
                                                        <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                                    </CButton>

                                                    <CButton
                                                        title="Quitar archivo"
                                                        color="danger" 
                                                        size="sm"
                                                        @click.prevent="$refs.upload.remove(archivo)">
                                                        <CIcon :content="$options.freeSet.cilTrash"/>
                                                    </CButton>
                                                    
                                                </CButtonGroup>
                                            </template>
                                            
                                            
                                        </a-table>
                                        <div v-if="!archivosSubir" class="text-center p-5">
                                        <h4>Arrastra aquí una carpeta o un archivo</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="option" v-show="isOption">
                                    <div class="form-group">
                                        <label for="thread">Thread:</label>
                                        <input
                                        type="number"
                                        max="5"
                                        min="1"
                                        id="thread"
                                        class="form-control"
                                        v-model.number="thread"
                                        />
                                        <small class="form-text text-muted"
                                        >Also upload the number of files at the same time (number of
                                        threads)</small
                                        >
                                    </div>

                                    <div class="form-group">
                                        <label for="autoCompress">Automatically compress:</label>
                                        <input
                                        type="number"
                                        min="0"
                                        id="autoCompress"
                                        class="form-control"
                                        v-model.number="autoCompress"
                                        />
                                        <small class="form-text text-muted" v-if="autoCompress > 0"
                                        >More than {{ autoCompress | prettyBytes }} files are automatically
                                        compressed</small
                                        >
                                        <small class="form-text text-muted" v-else
                                        >Set up automatic compression</small
                                        >
                                    </div>

                                    <div class="form-group">
                                        <div class="form-check">
                                        <label class="form-check-label">
                                            <input
                                            type="checkbox"
                                            id="add-index"
                                            class="form-check-input"
                                            v-model="addIndex"
                                            />
                                            Start position to add
                                        </label>
                                        </div>
                                        <small class="form-text text-muted"
                                        >Add a file list to start the location to add</small
                                        >
                                    </div>

                                    <div class="form-group">
                                        <div class="form-check">
                                        <label class="form-check-label">
                                            <input
                                            type="checkbox"
                                            id="drop"
                                            class="form-check-input"
                                            v-model="drop"
                                            />
                                            Drop
                                        </label>
                                        </div>
                                        <small class="form-text text-muted">Drag and drop upload</small>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check">
                                        <label class="form-check-label">
                                            <input
                                            type="checkbox"
                                            id="drop-directory"
                                            class="form-check-input"
                                            v-model="dropDirectory"
                                            />
                                            Drop directory
                                        </label>
                                        </div>
                                        <small class="form-text text-muted"
                                        >Not checked, filter the dragged folder</small
                                        >
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check">
                                        <label class="form-check-label">
                                            <input
                                            type="checkbox"
                                            id="upload-auto"
                                            class="form-check-input"
                                            v-model="uploadAuto"
                                            />
                                            Auto start
                                        </label>
                                        </div>
                                        <small class="form-text text-muted"
                                        >Automatically activate upload</small
                                        >
                                    </div>
                                    <div class="form-group">
                                        <button
                                        type="button"
                                        class="btn btn-primary btn-lg btn-block"
                                        @click.prevent="isOption = !isOption"
                                        >
                                        Confirm
                                        </button>
                                    </div>
                                </div>
                                <div
                                    :class="{ modal: true, fade: true, show: addData.show }"
                                    id="modal-add-data"
                                    tabindex="-1"
                                    role="dialog"
                                >
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Add data</h5>
                                            <button
                                            type="button"
                                            class="close"
                                            @click.prevent="addData.show = false"
                                            >
                                            <span>&times;</span>
                                            </button>
                                        </div>
                                        <form @submit.prevent="onAddData">
                                            <div class="modal-body">
                                            <div class="form-group">
                                                <label for="name">Name:</label>
                                                <input
                                                type="text"
                                                class="form-control"
                                                required
                                                id="name"
                                                placeholder="Please enter a file name"
                                                v-model="addData.name"
                                                />
                                                <small class="form-text text-muted"
                                                >Such as <code>filename.txt</code></small
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label for="type">Type:</label>
                                                <input
                                                type="text"
                                                class="form-control"
                                                required
                                                id="type"
                                                placeholder="Please enter the MIME type"
                                                v-model="addData.type"
                                                />
                                                <small class="form-text text-muted"
                                                >Such as <code>text/plain</code></small
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label for="content">Content:</label>
                                                <textarea
                                                class="form-control"
                                                required
                                                id="content"
                                                rows="3"
                                                placeholder="Please enter the file contents"
                                                v-model="addData.content"
                                                ></textarea>
                                            </div>
                                            </div>
                                            <div class="modal-footer">
                                            <CButton
                                                class="btnPrincipalVapp"
                                                size="sm"
                                                @click.prevent="addData.show = false"
                                                >Close
                                            </CButton>
                                            <CButton class="btnPrincipalVapp" size="sm">Save </CButton>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    
    import cons from '@/const'
    import axios from "axios"

    import Multiselect from 'vue-multiselect'

    import FileUpload from "vue-upload-component";
    import { freeSet } from '@coreui/icons'

    
    export default {
        name: 'Migracion',
        freeSet,
        components: {
            Multiselect,
            FileUpload
        },
        data () {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                
                urlBase: cons.port+"://" + cons.ipServer,
                empSelected: null,
                empresasInfo: [],
                isLoadingEmpresasInfo: false,
                isOption: false,
                linkSubir: `${cons.port+"://" + cons.ipServer}/subir_archivos_migracion`,
                archivosSubir: [],
                isLoadingSubir: false,
                mostrarSubirArchivos: false,

                periodoAnual: false,
                tipoMigSeleccionado: null,
                tiposMigracion: [{"nombre": "Transtecnia", "tipo": "trc"}, {"nombre": "Otro", "tipo": "otr"}],

                anioSeleccionado: null,
                aniosPeriodo: [{"per": "p1"}, {"per": "p2"}],
                mesSeleccionado: null,
                mesesPeriodo: [{"mes": "m1"}, {"mes": "m2"}],

                columnasSubidaArchivos: [
                    { title: 'Nombre Archivo', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Progreso', scopedSlots:{ customRender: 'progreso' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Tamaño', scopedSlots: { customRender: 'tamanio' }, width: '10%', align: 'left', ellipsis: false},
                    { title: 'Velocidad', scopedSlots: { customRender: 'velocidad' }, width: '10%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, width: '20%', align: 'center', ellipsis: false},
                ],

                files: [],
                accept: "",
                extensions: "",
                minSize: 1024,
                size: 0,
                multiple: true,
                directory: false,
                drop: true,
                dropDirectory: true,
                addIndex: false,
                thread: 3,
                name: "file",
                putAction: "",
                headers: {
                    Authorization: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                },
                data: {},
                autoCompress: 1024 * 1024,
                uploadAuto: false,
                isOption: false,
                addData: {
                    show: false,
                    name: "",
                    type: "",
                    content: "",
                },
                editFile: {
                    show: false,
                    name: "",
                },
                
            }
        },
        created: function(){
        },
        watch: { 
            "editFile.show"(newValue, oldValue) {
                if (!newValue && oldValue) {
                this.$refs.upload.update(this.editFile.id, {
                    error: this.editFile.error || "",
                });
                }
                if (newValue) {
                this.$nextTick(function () {
                    if (!this.$refs.editImage) {
                    return;
                    }
                    let cropper = new Cropper(this.$refs.editImage, {
                    autoCrop: false,
                    });
                    this.editFile = {
                    ...this.editFile,
                    cropper,
                    };
                });
                }
            },
            "addData.show"(show) {

                if (show) {
                this.addData.name = "";
                this.addData.type = "";
                this.addData.content = "";
                }
            },
        },
        beforeMount() {
            this.getdData()
            this.obtenerSoloInfoEmpresas()

        },
        methods: {
            seleccionarEmpresa: function(){

            },
            getdData: function(){
               
            },
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = this.urlBase + "/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },

            subirDocumentosMostrar: function(){
                this.mostrarSubirArchivos = true
                let mesSel = (this.mesSeleccionado != null) ? this.mesSeleccionado.mes : null
                this.linkSubir = `${this.urlBase}/subir_archivos_migracion/${this.empSelected.idEmpresa}/${this.anioSeleccionado.per}/${mesSel}/${this.tipoMigSeleccionado.tipo}/${this.periodoAnual}`
                
            },
            cambiarSeleccionPeriodoAnual: function(){

                this.periodoAnual = !this.periodoAnual
                if(this.empSelected != null && this.anioSeleccionado != null && this.tipoMigSeleccionado != null){
                    console.log("entro1")
                    if(!this.periodoAnual && this.mesSeleccionado != null){
                        console.log("entro2")
                        this.subirDocumentosMostrar()
                    }
                    else{
                        console.log("entro3")
                        if(this.periodoAnual && this.mesSeleccionado == null){
                            this.subirDocumentosMostrar()
                            console.log("entro4")
                        } else{
                            console.log("entro5")
                            if(this.periodoAnual && this.mesSeleccionado != null){
                                console.log("entro6")
                                this.subirDocumentosMostrar()
                            }
                            else{
                                console.log("entro7")
                                this.mostrarSubirArchivos = false
                            }

                        }
                    }
                }
            },
            
            
            
            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },


            
            subirTodoActivar: function(empresa){
                if(subidaDocsID == null){
                    
                this.$refs.upload.active = true
                this.validarDocsError(empresa)

                    var idInt = setInterval(function(){
                        this.validarDocumentosSubidos(empresa);
                    }.bind(this), 1000);
                    subidaDocsID = idInt
                }

            },
            validarDocsError: function(empresa){
                archivosSubir.forEach(archivo => {
                    if(!archivo.success && archivo.error != ""){
                        this.$refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})
                    }
                });

            },
            validarDocumentosSubidos: function(empresa){
                if(archivosSubir.length == 0){
                    clearInterval(subidaDocsID)
                    subidaDocsID = null
                }
                else{
                    var finalizados = 0
                    archivosSubir.forEach(archivo => {
                        if((!archivo.active && parseInt(archivo.progress) == 100) || (!archivo.active && archivo.error == "Cancelado") || (!archivo.active && archivo.success) || (!archivo.active && !archivo.success)){
                            finalizados++
                        }
                    })
                    if(finalizados == archivosSubir.length){
                        clearInterval(subidaDocsID)
                        subidaDocsID = null
                        this.obtenerTrabajadores(true, empresa)
                    }
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Before adding a file
                    // 添加文件前
                    // Filter system files or hide files
                    // 过滤系统文件 和隐藏文件
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                        return prevent();
                    }
                    // Filter php html js file
                    // 过滤 php html js 文件
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                        return prevent();
                    }
                    // Automatic compression
                    // 自动压缩
                    if (
                        newFile.file &&
                        newFile.type.substr(0, 6) === "image/" &&
                        this.autoCompress > 0 &&
                        this.autoCompress < newFile.size
                        ) {
                        newFile.error = "compressing";
                        const imageCompressor = new ImageCompressor(null, {
                            convertSize: Infinity,
                            maxWidth: 512,
                            maxHeight: 512,
                        });
                        imageCompressor
                            .compress(newFile.file)
                            .then((file) => {
                            this.$refs.upload.update(newFile, {
                                error: "",
                                file,
                                size: file.size,
                                type: file.type,
                            });
                            })
                            .catch((err) => {
                            this.$refs.upload.update(newFile, {
                                error: err.message || "compress",
                            });
                            });
                        }
                    }
                    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                        // Create a blob field
                        // 创建 blob 字段
                        newFile.blob = "";
                        let URL = window.URL || window.webkitURL;
                        if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file);
                        }
                        // Thumbnails
                        // 缩略图
                        newFile.thumb = "";
                        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
                        newFile.thumb = newFile.blob;
                        }
                    }
            },
            // add, update, remove File Event
            inputFile(newFile, oldFile) {
                if (newFile && oldFile) {
                    // update
                    if (newFile.active && !oldFile.active) {
                    // beforeSend
                    // min size
                    if (
                        newFile.size >= 0 &&
                        this.minSize > 0 &&
                        newFile.size < this.minSize
                    ) {
                        this.$refs.upload.update(newFile, { error: "size" });
                    }
                    }
                    if (newFile.progress !== oldFile.progress) {
                    // progress
                    
                    }
                    if (newFile.error && !oldFile.error) {
                    // error
                    }
                    if (newFile.success && !oldFile.success) {
                    // success
                    }
                }
                if (!newFile && oldFile) {
                    // remove
                    if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/upload/delete?id=' + oldFile.response.id,
                    // })
                    }
                }
                // Automatically activate upload
                if (
                    Boolean(newFile) !== Boolean(oldFile) ||
                    oldFile.error !== newFile.error
                ) {
                    if (this.uploadAuto && !this.$refs.upload.active) {
                    this.$refs.upload.active = true;
                    }
                }
            },
            alert(message) {
                alert(message);
            },
            onEditFileShow(file) {
                this.editFile = { ...file, show: true };
                this.$refs.upload.update(file, { error: "edit" });
            },
            onEditorFile() {
                if (!this.$refs.upload.features.html5) {
                    this.alert("Your browser does not support");
                    this.editFile.show = false;
                    return;
                }
                let data = {
                    name: this.editFile.name,
                };
                if (this.editFile.cropper) {
                    let binStr = atob(
                    this.editFile.cropper
                        .getCroppedCanvas()
                        .toDataURL(this.editFile.type)
                        .split(",")[1]
                    );
                    let arr = new Uint8Array(binStr.length);
                    for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i);
                    }
                    data.file = new File([arr], data.name, { type: this.editFile.type });
                    data.size = data.file.size;
                }
                this.$refs.upload.update(this.editFile.id, data);
                this.editFile.error = "";
                this.editFile.show = false;
            },
            // add folader
            onAddFolader() {
                if (!this.$refs.upload.features.directory) {
                    this.alert("Your browser does not support");
                    return;
                }
                let input = this.$refs.upload.$el.querySelector("input");
                input.directory = true;
                input.webkitdirectory = true;
                this.directory = true;
                input.onclick = null;
                input.click();
                input.onclick = (e) => {
                    this.directory = false;
                    input.directory = false;
                    input.webkitdirectory = false;
                };
            },
            onAddData() {
                this.addData.show = false;
                if (!this.$refs.upload.features.html5) {
                    this.alert("Your browser does not support");
                    return;
                }
                let file = new window.File([this.addData.content], this.addData.name, {
                    type: this.addData.type,
                });
                this.$refs.upload.add(file);
            },

        }
        
    }
</script>